import * as React from 'react'
import Layout from '/src/components/layout'

const ForNatverkPage = () => {

    return (
        <Layout pageTitle="För nätverk">
            
            <p class="intro">Laddnätverk i Sverige, Norge, Danmark och Finland är hjärtligt välkomna höra av sig till <a href="mailto:hej@laddify.se">hej@laddify.se</a> för att visas i Laddify.</p>
            
            
            <h2>Hur gör vi?</h2>
            <p>Varmt välkommen att höra av dig/er till <a href="mailto:hej@laddify.se">hej@laddify.se</a>! Skicka gärna med information kring hur Laddify kan hämta information kring nätverket (API) och om det är möjligt för Laddify att erhålla statusuppdateringar (webhooks).</p>


            <h2>Vad behövs?</h2>

            <h3>Logotyp</h3>
            <p>I Laddify finns en sida för varje laddstation och på denna sida föreslås er logga visas under rubriken "Nätverk" istället för endast ert namn. Samt även i kartvyn när användaren klickar på en laddstation (vilket via läs mer-länk leder till sidan för stationen).</p>

            <h3>API</h3>
            <p>För att på ett kvalitativt och effektivt sätt kunna hämta information om ert nätverk behövs åtkomst till ett API (eller via OCPI se nedan). Laddify hämtar från nätverkets API och det är sedan från Laddify som användare hämtar informationen. Användare av Laddify anropar aldrig erat API direkt. Med andra ord får ert API minimalt med extra belastning samtidigt som ni får ökad exponering av era stationer och ert varumärke. I Laddify framgår var informationen kommer ifrån och naturligtvis även att ni inte kan hållas ansvariga för informationen eller Laddify.</p>

            <h3>Webhook</h3>
            <p>Om möjligt vore det även önskvärt med webhook för att kunna prenumerera på statusuppdateringar för laddpunkterna så att onödig mängd API-anrop för detta kan undvikas.</p>

            <h3>OCPI</h3>
            <p>Om ni har möjlighet att integrera över OCPI så går även det bra (version 2.2 &amp; 2.2.1). Rollen NSP (Navigation Service Providers) passar bäst in på Laddify (eMSP är inte rätt eftersom man inte kan starta laddning &amp; betala via Laddify).</p>

            <h3>Direkt-länk</h3>
            <p>Ett sätt att underlätta för elbilsförare är att direkt från Laddify kunna klicka sig vidare till er webbsida eller app där förare kan starta igång laddning för den aktuella stationen/laddpunkten. Detta istället för att manuellt behöva leta upp rätt app/webbsida, hitta rätt laddstation, rätt laddpunkt och starta laddningen. Även smidigt för de som inte vill använda appar eller att registera sig utan direkt via webben kunna betala med betalkort. Tekniskt sätt samma sak som en QR-kod på laddaren men användaren slipper eventuella praktiska bekymmer med ett fysiskt klistermärke såsom mörker, väder, skadegörelse etc.</p>
            <p>Exempelvis: <i>nätverk.se/betala-laddning/{'{stationsID eller uttagsID}'}</i></p>


            <h2>Vilken information behövs?</h2>
            <p>Laddify visar endast publika laddstationer och önskar nedan information. Obligatorisk information är markerad med en röd stjärna: <span style={{color: 'red'}}>*</span></p>
            <p><b>Stationer:</b><br/>
                <ul>
                    <li>ID <span style={{color: 'red'}}>*</span></li>
                    <li>Namn</li>
                    <li>Adress (gata, postnummer, ort &amp; land)</li>
                    <li>Longitud &amp; Latitud <span style={{color: 'red'}}>*</span></li>
                    <li>Laddpunkter <span style={{color: 'red'}}>*</span></li>
                    <li>XXX</li>
                    <li>XXX</li>
                </ul>
            </p>

            <p><b>Laddpunkter:</b><br/>
                <ul>
                    <li>ID <span style={{color: 'red'}}>*</span></li>
                    <li>EVSEID</li>
                    <li>Namn</li>
                    <li>Pris</li>
                    <li>Betalsätt (ex. app, bricka/tagg, betalkort på plats, betalkort online, Swish, SMS osv.)</li>
                    <li>Uttagstyper (Typ 2/CCS osv.) <span style={{color: 'red'}}>*</span></li>
                    <li>Uttagstypernas effekt (kW) <span style={{color: 'red'}}>*</span></li>
                </ul>
            </p>


            <h2>Vad får vi?</h2>
            <p>Genom att för Laddify tillhandahålla gratis API:er och eventuell webhook visas era stationer i Laddify och ni får på så vis ökad exponering av ert nätverk.</p>
            <p>Framöver får ni också statistik över hur mycket era stationer visas i Laddify och kommer kunna besvara incheckningar, exempelvis tacka för feedback eller svara att fel är åtgärdat.</p>
            

            <h2>Saknar karta på webbsidan?</h2>
            <p>I höst kommer de nätverk som önskar mot en låg kostnad kunna på sin egen webbsida visa en karta med endast sina egna laddplatser. Vid intresse e-posta <a href="mailto:hej@laddify.se">hej@laddify.se</a> för mer information.</p>
            

            <h2>Konkurrerar Laddify med oss?</h2>
            <p>Nej. Varken Laddify eller de som står bakom Laddify har några som helst planer på att bygga några nätverk/laddstationer eller att agera roamingoperatör. Syftet med Laddify är att erbjuda elbilsförare ett riktigt bra sätt att hitta publika laddstationer. Likt hur Google hjälper människor att hitta rätt webbsida utan att de står bakom alla webbsidor eller hur Prisjakt/Pricerunner hjälper människor att filtrera och hitta rätt produkt för att sedan länka vidare till butikernas egna webbshoppar.</p>

        </Layout>
    )
}

export default ForNatverkPage